<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <v-menu
                    v-model="disposal_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="disposal_date" name="Disposal Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.disposal_date"
                          label="Disposal Date*"
                          hint="Disposal / Decree Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                          clearable
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.disposal_date"
                      @input="disposal_date_menu=false"
                      :max=max_date
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="disposal_through" name="Disposal Through"
                                    rules="objectNotEmpty:disposal_through|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Disposal Through*"
                      hint="Disposal Status/Through"
                      :items="disposal_through"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.disposed_through"
                      required
                      autocomplete="nope"
                      :loading="disposal_through_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="disposal_nature" name="Disposal nature"
                                    rules="objectNotEmpty:disposal_nature|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Disposal Nature*"
                      hint="In favour of bank/Partial Decree/against bank"
                      :items="disposal_nature"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.disposal_nature"
                      required
                      autocomplete="nope"
                      :loading="disposal_nature_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="disposal_result" name="Disposal Result"
                                    rules="objectNotEmpty:disposal_result|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Disposal Result*"
                      hint="Disposal Result"
                      :items="disposal_result"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.disposal_result"
                      required
                      autocomplete="nope"
                      :loading="disposal_result_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <v-menu
                    v-model="decree_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="decree_date" name="Decree Date"
                                        rules=""
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.decree_date"
                          label="Decree Date*"
                          hint="Decree Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          clearable
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.decree_date"
                      @input="decree_date_menu=false"
                      :max=max_date
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="disposal_remarks" name="Disposal Remarks"
                                    v-slot="{ errors }">
                  <v-textarea
                      v-model="item.disposal_remarks"
                      label="Disposal Remarks"
                      hint="Disposal Remarks"
                      dense
                      clearable
                      auto-grow
                      row="1"
                  ></v-textarea>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import parsing_functions from "@/constants/util_functions/parsing_functions";
import app_setting_api from "@/constants/app_setting/app_setting_api";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "DisposeArthorinForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text',
    'tooltip_text', 'loading', 'color', 'small', 'file', 'file_conn', 'passed_item', 'mamla_conn', 'mamla'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    max_date: function () {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      return String(yyyy + '-' + mm + '-' + dd)
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    disposal_date_menu: false,
    decree_date_menu: false,
    disposal_through: [],
    disposal_through_loading: false,
    disposal_nature: [],
    disposal_nature_loading: false,
    disposal_result: [],
    disposal_result_loading: false,
    item: {},
    non_field_errors: [],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        id: this.mamla,
        ...this.item
      }
      await axios.patch(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            // this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.case_no),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                non_field: error.response.data.errors.non_field,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      this.add_new_item()
    },
    get_disposal_through() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.disposal_through = []
      this.disposal_through_loading = true
      axios.get(app_setting_api.disposal_through.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        // this.disposal_through = resp.data.items.filter(v => v.court_type.id === courtType)
        this.disposal_through = resp.data.items
        this.disposal_through_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get disposal through data", status: true})
        this.$refs.observer.setErrors({
          non_field: ["Unable to get disposal through data, contact admin"],
        });
      })
    },
    get_disposal_nature() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.disposal_nature = []
      this.disposal_nature_loading = true
      axios.get(app_setting_api.disposal_nature.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        // this.disposal_through = resp.data.items.filter(v => v.court_type.id === courtType)
        this.disposal_nature = resp.data.items
        this.disposal_nature_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get disposal nature data", status: true})
        this.$refs.observer.setErrors({
          non_field: ["Unable to get disposal nature data, contact admin"],
        });
      })
    },
    get_disposal_result() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.disposal_result = []
      this.disposal_result_loading = true
      let self = this
      axios.get(app_setting_api.disposal_result.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        if (this.passed_item.court) {
          this.disposal_result = resp.data.items.filter(v => v.court_type.id === self.passed_item.court.type.id)
        }
        // this.disposal_through = resp.data.items.filter(v => v.court_type.id === courtType)

        this.disposal_result_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get disposal result data", status: true})
        this.$refs.observer.setErrors({
          non_field: ["Unable to get disposal result data, contact admin"],
        });
      })
    },

    load_data() {
      this.get_disposal_through()
      this.get_disposal_nature()
      this.get_disposal_result()
    }
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let passed = JSON.parse(JSON.stringify(newValue))
        this.item.disposed_through = passed.disposed_through ? passed.disposed_through.id : null
        this.item.disposal_result = passed.disposal_result ? passed.disposal_result.id : null
        this.item.disposal_nature = passed.disposal_nature ? passed.disposal_nature.id : null
        this.item.disposal_date = passed.disposal_date ? parsing_functions.parse_date_to_standard(passed.disposal_date) : null
        this.item.disposal_remarks = passed.disposal_remarks ? passed.disposal_remarks : null
        if (this.dialog) {
          this.get_disposal_result()
        }
        // console.log(this.item)
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>