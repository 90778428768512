<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel class="ma-0 pa-0">
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0">
          <span class="font-weight-bold">
            Recovery Expense and History
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <v-card class="my-3 elevation-4 ma-0 pa-0">
          <v-tabs
              v-model="tab"
              background-color="orange"
              color="light-green accent-4"
              centered
              icons-and-text
              dense
              height="60"
              show-arrows
              class="ma-0 pa-0"
          >
            <v-tabs-slider class="ma-0 pa-0"></v-tabs-slider>
            <v-tab dense>
              <span class="black--text">Recovery Transactions</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab>
              <span class="black--text">Expense Transactions</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab>
              <span class="black--text">Lawyer History</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <v-tab>
              <span class="black--text">Next Day History</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="amber lighten-4 ma-0 pa-0">
            <v-tab-item class="pa-0 ma-0">
              <RecoveryTransactions :mamla_obj="info" :mamla="info.id" :file="info.file.id"/>
            </v-tab-item>
            <v-tab-item>
              <expense-transaction-details :mamla_obj="info" :mamla="info.id" :file="info.file.id"/>
            </v-tab-item>
            <v-tab-item>
              <LawyerHistory :passed_item="info"/>
            </v-tab-item>
            <v-tab-item>
              <NextDayPurposeHistory :passed_item="info"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import RecoveryTransactions
  from "@/components/mamla/loan_mamla/common_components/sub_components/recovery/RecoveryTransactions";
import ExpenseTransactionDetails
  from "@/components/mamla/loan_mamla/common_components/sub_components/expense/expenseTransactionDetails";
import LawyerHistory from "@/components/mamla/loan_mamla/common_components/sub_components/lawyer_history/LawyerHistory";
import NextDayPurposeHistory
  from "@/components/mamla/loan_mamla/common_components/sub_components/next_day_history/NextDayPurposeHistory";

export default {
  name: "HistoryViews",
  props: ['info'],
  components: {RecoveryTransactions, ExpenseTransactionDetails, LawyerHistory, NextDayPurposeHistory},
  data: () => ({
    tab: null,
  })
}
</script>

<style scoped>

</style>