<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <ValidationProvider mode="passive" vid="non_field" name="Non Field" rules="" v-slot="{ errors }">
                <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="involved_amount" name="Involved Amount"
                                    rules="required|min_value:1"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Involved Amount*"
                      hint="Total Amount Stuck/Involved in this file"
                      required
                      autocomplete="nope"
                      v-model="involved_amount"
                      maxlength="32"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'parent_item', 'loading'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    involved_amount: {
      get() {
        return JSON.parse(JSON.stringify(this.passed_item)).involved_amount
      },
      set(val) {
        this.item.involved_amount = val
      }
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    item: {
      id: null,
      involved_amount: 0
    },
    non_field_errors: []
  }),
  methods: {
    async edit_item() {
      this.axios_conf
      let data = {
        id: this.item.id,
        involved_amount: this.item.involved_amount
      }
      await axios.post(this.url, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.$emit('involved_amount', data.involved_amount)
            // console.log(this.passed_item)
            this.$store.commit("update_snackbar", {text: this.snackbar_text, status: true})
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                involved_amount: error.response.data.errors.involved_amount,
                non_field: error.response.data.errors.non_field_errors
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
  },
  watch: {
    // passed_item(val) {
    //   this.item = JSON.parse(JSON.stringify(val))
    //   // console.log(this.item)
    // },
    // item() {
    //   this.item = JSON.parse(JSON.stringify(this.passed_item))
    // }
    // passed_item: function (val) {
    //   this.item = JSON.parse(JSON.stringify(val))
    //   console.log(val)
    // }
  },
  mounted() {
    if (this.passed_item) {
      this.item = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>