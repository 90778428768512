<template>
  <FirstAppealHc :parent="parent" :seq="seq" :flow="flow">
    <template v-slot:first_appeal_hc_child="{parent, seq, flow}">
      <CplaContainer :parent="parent" :seq="seq" crpla="true" :flow="flow" class="my-1">
      </CplaContainer>
    </template>
  </FirstAppealHc>
</template>

<script>
import FirstAppealHc from "@/components/mamla/loan_mamla/loan_mamla_sub_components/first_appeal_hc/FirstAppealHc";
import CplaContainer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/cpla/CplaContainer";

export default {
  name: "Flow1",
  props: ['parent', 'seq', 'flow'],
  components: {CplaContainer, FirstAppealHc}
}
</script>

<style scoped>

</style>