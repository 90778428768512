<script>
import nlFileDetails from "@/components/nl_file/sub_components/nlFileDetails.vue";

export default {
  name: "nlFileDetailsPage",
  components: {nlFileDetails},
  methods: {},
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Non Loan Mamla Details'
    window.scrollTo(0, 0);
  }
}
</script>

<template>
  <nl-file-details/>
</template>

<style scoped>

</style>