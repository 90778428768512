<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel class="ma-0 pa-0">
      <v-expansion-panel-header color="amber accent-1" expand-icon="mdi-cash-multiple"
                                class="elevation-9 ma-0" @click="clicked=!clicked">
          <span class="text-h6">
            Company Matter
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="ma-0 pa-0" color="amber lighten-4">
        <multiCompanyMatter :clicked="clicked"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import multiCompanyMatter from "@/components/mamla/companyMatter/multiCompanyMatter.vue";

export default {
  name: "companyMatterContainer",
  components: {multiCompanyMatter},
  data: () => ({
    clicked: false,
  })
}
</script>

<style scoped>

</style>