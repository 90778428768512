<template>
  <v-container class="pa-1 ma-0">

    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <span class="font-weight-bold">{{ banner_text }}</span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Petitioner's Name</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span class="font-weight-bold">{{ info ? info.petitioner_name : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Respondent's Name</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.respondent_name : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Case Number</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span class="font-weight-bold">{{ info ? info.case_number : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Filing Date</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.filing_date : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Cause</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? (info.cause ? info.cause.cause : null) : null }}</span><br/>
        <span>{{ info ? (info.cause_details ? info.cause_details : null) : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Case Filed By</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? (info.case_filed_by ? info.case_filed_by.name : null) : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Claimed Amount</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.claimed_amnt : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">District</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.district ? info.district.name : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Rule Issue Date</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.rule_issue_date : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Direction of the court</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span style="overflow-wrap: break-word">{{ info.direction_court }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Direction Binding on</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.direction_binding ? info.direction_binding.name : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Deadline of Complying Direction</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span style="overflow-wrap: break-word">{{ info.direction_deadline }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Whether direction is complied</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.direction_complied : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Stay Order?</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span style="overflow-wrap: break-word">{{ info.stay_order }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Stay Order duration</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.stay_order_duration : null }}days</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Stay Order Issue Date</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span style="overflow-wrap: break-word">{{ info.stay_order_issue_date }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Date of submitting affidavit</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? info.affidavit_date : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">&nbsp;</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span style="overflow-wrap: break-word">&nbsp;</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Court Name</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.court ? info.court.name : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Court No</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.court_no }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Lawyer</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.lawyer ? info.lawyer.name : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Engage Date</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.lawyer_engage_date }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Next Date</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.next_date }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Purpose</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.purpose ? info.purpose.name : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Purpose Remarks</span>
      </v-col>
      <v-col cols="3" style="overflow-wrap: break-word;" class="text-right">
        <span>{{ info.purpose_remarks }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposed?</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span class="font-weight-bold red--text">{{ info.dispose_status ? 'True' : 'False' }}</span>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposal Date</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.disposal_date }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposal Through</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.disposed_through ? info.disposed_through.name : null }}</span>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposal Nature</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.disposal_nature ? info.disposal_nature.name : null }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposal Result</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.disposal_result ? info.disposal_result.name : null }}</span>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposal Remarks</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.disposal_remarks }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Disposing Period</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? (info.disposing_period ? info.disposing_period.name : null) : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Input Timestamp</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.timestamp }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Input By</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? (info.user ? info.user.username : null) : null }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center">
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Last updated on</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info.update_timestamp }}</span>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="3" class="text-left">
        <span class="font-weight-bold">Updated By</span>
      </v-col>
      <v-col cols="3" class="text-right">
        <span>{{ info ? (info.update_by ? info.update_by.username : null) : null }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MamlaDetails",
  props: ['info', 'banner_text']
}
</script>

<style scoped>

</style>