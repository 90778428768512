const prefix = "company_matter"
const companyMatterUrls = {
    list: `${prefix}`,
    add: `${prefix}`,
    single: `${prefix}?id=`,
    add_blank: `${prefix}?blank=True`,
    by_file_id: `${prefix}?file_id=`,
    by_subject_id: `${prefix}?subject_id=`,
    dispose: `${prefix}/dispose_company_matter/`,
};

export default companyMatterUrls;