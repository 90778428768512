var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},on:{"input":_vm.load_data},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"color":_vm.color,"small":_vm.small}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}],null,true)},[(!_vm.big_btn)?_c('span',[_vm._v(_vm._s(_vm.tooltip_text))]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"non_field","name":"None Field","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((errors),function(err){return _c('ul',{key:err},[_c('li',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])])})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"eager","vid":"date","name":"Receiving Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Receiving Date of Judgement's Certified Copy*","hint":"Receiving Date of Judgement's Certified Copy","prepend-icon":"mdi-calendar","readonly":"","required":"","dense":""},model:{value:(_vm.item.date_certified_judgement),callback:function ($$v) {_vm.$set(_vm.item, "date_certified_judgement", $$v)},expression:"item.date_certified_judgement"}},'v-text-field',attrs,false),on)),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})]}}],null,true),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_menu=false}},model:{value:(_vm.item.date_certified_judgement),callback:function ($$v) {_vm.$set(_vm.item, "date_certified_judgement", $$v)},expression:"item.date_certified_judgement"}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }