<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel class="ma-0 pa-0">
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0">
          <span class="font-weight-bold">
            First Appeal(HC)
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <FirstAppealHc :parent="parent" :seq="seq" :flow="flow"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import FirstAppealHc from "@/components/mamla/loan_mamla/loan_mamla_sub_components/first_appeal_hc/FirstAppealHc";

export default {
  name: "firstAppealHcContainer",
  props: ['parent', 'seq', 'flow'],
  components: {FirstAppealHc}
}
</script>

<style scoped>

</style>