const bankruptcy_urls = {
    get_by_file: "mamla/loanmamla/bankruptcy_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/bankruptcy_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/bankruptcy/",
    blank: "mamla/loanmamla/bankruptcy_blank/",
    disposal: "mamla/loanmamla/bankruptcy_disposal/",
    single: "mamla/loanmamla/bankruptcy/{id}/",
    delete: "mamla/loanmamla/bankruptcy/{id}/",
    add: "mamla/loanmamla/bankruptcy/",
}

export default bankruptcy_urls;