const prefix = "nl_file"
const NlFile = {
    file: {
        list: "nl_file",
        list_by_id: (id) => `${prefix}/${id}`,
        add: `${prefix}/`,
        select: `${prefix}/{fileid}`,
        delete: `${prefix}/{fileid}`,
    },
    involved_amount: {
        edit: (id) => `${prefix}/change_involved_amnt/${id}`
    },
    dealing_officer: {
        edit: (id) => `${prefix}/change_dealing_officer/${id}`
    }
}

export default NlFile;