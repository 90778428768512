const writ_mamla_urls = {
    get_by_file: "mamla/loanmamla/writ_by_file/{fileid}/",
    get_by_parent: "mamla/loanmamla/writ_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/writ/",
    blank: "mamla/loanmamla/writ_blank/",
    disposal: "mamla/loanmamla/writ_disposal/",
    single: "mamla/loanmamla/writ/{id}/",
    add: "mamla/loanmamla/writ/"
}

export default writ_mamla_urls;