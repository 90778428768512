const cpla_mamla_urls = {
    get_by_file: "mamla/loanmamla/cpla_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/cpla_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/cpla/",
    blank: "mamla/loanmamla/cpla_blank/",
    disposal: "mamla/loanmamla/cpla_disposal/",
    single: "mamla/loanmamla/cpla/{id}/",
    add: "mamla/loanmamla/cpla/"
}

export default cpla_mamla_urls;