<template>
  <v-container>
    <v-expansion-panels class="elevation-9 rounded-xl">
      <v-expansion-panel>
        <v-expansion-panel-header color="orange" expand-icon="mdi-account-network" class="elevation-9">
          <span class="font-weight-bold">
            Dealing Officer
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="amber lighten-4 ">
          <v-expansion-panel-content color="amber lighten-4"></v-expansion-panel-content>
          <v-row align="center" justify="center">
            <v-col cols="3" class="text-center">
              <span class="font-weight-bold">Name</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span>{{ passed_item.dealing_officer.name }}</span>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="3" class="text-center">
              <span class="font-weight-bold">Father's Name</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span>{{ passed_item.dealing_officer.father_name }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center">
            <v-col cols="3" class="text-center">
              <span class="font-weight-bold">Personnel No</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span>{{ passed_item.dealing_officer.personnel_no }}</span>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="3" class="text-center">
              <span class="font-weight-bold">Mobile No</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span>{{ passed_item.dealing_officer.mobile_no }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center">
            <v-col cols="3" class="text-center">
              <span class="font-weight-bold">Email</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span style="overflow-wrap: break-word">{{ passed_item.dealing_officer.email }}</span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span class="font-weight-bold"></span>
            </v-col>
            <v-col cols="3" class="text-center">
              <span></span>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
              <add_edit_form v-if="edit_button" activator_name="Change"
                             form_title="Change Dealing Officer" :passed_item="computed_passed"
                             :url="change_officer"
                             activator_icon="mdi-pencil" action="add"
                             tooltip_text="Change Dealing Officer"
                             big_btn="true" color="orange" @done_event="update_file"
                             snackbar_text="Dealing Officer has Changed Successfully">
              </add_edit_form>
            </v-col>
          </v-row>
          <DealingOfficerHistory :passed_item="computed_passed"></DealingOfficerHistory>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from "axios";
import loan_mamla_urls from "@/constants/mamla/loan_mamla/loan_mamla";
import add_edit_form from "@/components/mamla/loan_mamla/loan_mamla_sub_components/dealing_officer/add_edit_form"
import DealingOfficerHistory
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/dealing_officer/DealingOfficerHistory";

export default {
  name: "DealingOfficer",
  props: ['passed_item'],
  components: {add_edit_form, DealingOfficerHistory},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('mamla_container.edit_dealing_officer')
    },
    computed_passed: {
      get() {
        return this.passed_item
      }
    }
  },
  data: () => ({
    item: null,
    change_officer: loan_mamla_urls.loan_mamla.dealing_officer
  }),
  methods: {
    update_file(val) {
      // console.log(val)
      this.$emit('update_dealing_officer', val)
    }
  },
  mounted() {
    this.item = JSON.parse(JSON.stringify(this.passed_item.dealing_officer))
  },
  watch: {}
}
</script>

<style scoped>

</style>