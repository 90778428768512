<template>
  <v-container class="ma-0 pa-0">
    <v-skeleton-loader
        type="table-heading, list-item-two-line,table-tfoot"
        class="mx-auto"
        v-bind="attrs"
        v-if="loader"
    >
    </v-skeleton-loader>
    <v-container v-if="!loader" class="pa-0 ma-0">
      <v-row align="center" justify="center" class="my-3">
        <v-col cols="auto" align="center" justify="center">
          <addForm v-if="add_button" activator_name="Add New Company matter Case"
                   form_title="Add New"
                   :url="urls.add_url" :file="this.$route.params.fileid"
                   :seq=seq
                   :flow="flow"
                   activator_icon="mdi-plus" action="add"
                   tooltip_text="Add New"
                   big_btn="true" color="orange" @done_event="update_event_handler"
                   snackbar_text="New Company Matter Case is added successfully"
          />
        </v-col>
        <v-col cols="auto" align="center" justify="center">
          <addBlankForm v-if="add_blank_button" @done_event="update_event_handler"
                        :file="this.$route.params.fileid"
                        dialogue_text="Are you sure you, you want to create a blank entry?"
                        :url="urls.add_blank"
                        :seq=seq
                        :flow="flow"
                        activator_name="Create a blank Company Matter Mamla"
                        activator_icon="mdi-file-hidden"
                        tooltip_text="Blank Company Matter Case"
                        snackbar_text="Blank Company Matter case has been created"
                        action="blank"
                        big_btn="true" color="orange" :small=false
          >
          </addBlankForm>
        </v-col>
      </v-row>
      <v-expansion-panels class="rounded-xl ma-0 pa-0">
        <v-expansion-panel class="my-1 pa-0" v-for="item in info" :key="item.id">
          <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0"
                                    @click="child_clicked=!child_clicked">
            <span class="font-weight-bold">
                Company Matter
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
            <childCase :seq="seq" :flow="flow" :item="item"
                       @delete_signal="delete_event_handler" @add_signal="update_event_handler"
                       :child_writ_clicked="child_clicked" title="Company Matter" :perm_array="permArray"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-container>
</template>

<script>
import createAxiosClient from "@/helpers/axios_helper";
import companyMatterUrls from "@/constants/urls/companyMatterUrls";
import childCase from "@/components/mamla/companyMatter/subComponents/childCase.vue";
import addForm from "@/components/mamla/companyMatter/subComponents/addForm.vue";
import comapnyMatterPerms from "@/constants/permissions/comapnyMatterPerms";
import addBlankForm from "@/components/mamla/companyMatter/subComponents/addBlankForm.vue";

export default {
  name: "multiCompanyMatter",
  props: ['clicked'],
  components: {
    childCase,
    addForm,
    addBlankForm
  },
  computed: {
    add_button: function () {
      return this.$store.getters.permissionCheckers(comapnyMatterPerms.add) && !this.loader
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers(comapnyMatterPerms.add_blank) && !this.loader
    },
  },
  data: () => ({
    urls: {
      get_url: companyMatterUrls.by_file_id,
      add_url: companyMatterUrls.add,
      add_blank: companyMatterUrls.add_blank
    },
    seq: 1,
    flow: 7,
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    loader: true,
    info: {},
    child_clicked: false,
    permArray: comapnyMatterPerms
  }),
  methods: {
    async get_info() {
      this.loader = true
      let client = createAxiosClient()

      client.get(this.urls.get_url + this.$route.params.fileid).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.data
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    mamla_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_event_handler() {
      this.get_info()
    }
    ,
    delete_event_handler() {
      this.get_info()
    }
  },
  mounted() {
    this.get_info()
  },
  watch: {
    clicked: {
      handler: async function (val, oldVal) {
        oldVal
        if (val) {
          await this.get_info()
        }
      },
    }
  }

}
</script>

<style scoped>

</style>