var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},on:{"input":_vm.load_data},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"color":_vm.color,"small":_vm.small}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}],null,true)},[(!_vm.big_btn)?_c('span',[_vm._v(_vm._s(_vm.tooltip_text))]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"non_field","name":"None Field","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((errors),function(err){return _c('ul',{key:err},[_c('li',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])])})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"petitioner_name","name":"Petitioner's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Petitioner/Appellant/Revisioner's Name*","hint":"Petitioner/Appellant/Revisioner's Name","dense":"","clearable":"","auto-grow":"","rows":"1","counter":"","maxlength":"1024"},model:{value:(_vm.item.petitioner_name),callback:function ($$v) {_vm.$set(_vm.item, "petitioner_name", $$v)},expression:"item.petitioner_name"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"respondent_name","name":"Respondent's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Respondent's Name*","hint":"Respondent's Name","dense":"","clearable":"","auto-grow":"","rows":"1","counter":"","maxlength":"1024"},model:{value:(_vm.item.respondent_name),callback:function ($$v) {_vm.$set(_vm.item, "respondent_name", $$v)},expression:"item.respondent_name"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"cause","name":"Cause","rules":"objectNotEmpty:cause|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Cause*","hint":"Case of the case","items":_vm.causes,"item-text":function (item){ return item.cause; },"item-value":"code","requiredkl":"","autocomplete":"nope","loading":_vm.causes_loading,"dense":""},model:{value:(_vm.item.cause),callback:function ($$v) {_vm.$set(_vm.item, "cause", $$v)},expression:"item.cause"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"cause_details","name":"Cause Details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Cause Details*","hint":"Cause Details","dense":"","clearable":"","auto-grow":"","rows":"1","counter":"1024"},model:{value:(_vm.item.cause_details),callback:function ($$v) {_vm.$set(_vm.item, "cause_details", $$v)},expression:"item.cause_details"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"case_filed_by","name":"Case Filed by","rules":"objectNotEmpty:case_filed_by|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Identity of petitioner*","hint":"Whomever filed the case","items":_vm.filed_by,"item-text":function (item){ return item.name; },"item-value":"code","requiredkl":"","autocomplete":"nope","loading":_vm.filed_by_loading,"dense":""},model:{value:(_vm.item.case_filed_by),callback:function ($$v) {_vm.$set(_vm.item, "case_filed_by", $$v)},expression:"item.case_filed_by"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"case_number","name":"Case Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Case Number*","hint":"Case Number for the mamla","required":"","autocomplete":"nope","maxlength":"256","counter":"","dense":""},model:{value:(_vm.item.case_number),callback:function ($$v) {_vm.$set(_vm.item, "case_number", $$v)},expression:"item.case_number"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"eager","vid":"filing_date","name":"Filing Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filing Date*","hint":"Date on which case is filed on the court","prepend-icon":"mdi-calendar","readonly":"","required":"","dense":""},model:{value:(_vm.item.filing_date),callback:function ($$v) {_vm.$set(_vm.item, "filing_date", $$v)},expression:"item.filing_date"}},'v-text-field',attrs,false),on)),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})]}}],null,true),model:{value:(_vm.filing_date_menu),callback:function ($$v) {_vm.filing_date_menu=$$v},expression:"filing_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.filing_date_menu=false}},model:{value:(_vm.item.filing_date),callback:function ($$v) {_vm.$set(_vm.item, "filing_date", $$v)},expression:"item.filing_date"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"claimed_amnt","name":"Claimed Amount","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Amount Stuck up*","hint":"Claimed Amount for this mamla","required":"","autocomplete":"nope","maxlength":"32","counter":"","clearable":"","dense":"","type":"number"},model:{value:(_vm.item.claimed_amnt),callback:function ($$v) {_vm.$set(_vm.item, "claimed_amnt", $$v)},expression:"item.claimed_amnt"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"lawyer","name":"Lawyer","rules":"objectNotEmpty:lawyer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Lawyer*","hint":"Search by lawyer name and mobile no","items":_vm.lawyer,"item-text":function (item){ return item.name + ' | ' + item.mobile_no; },"item-value":"id","requiredkl":"","autocomplete":"nope","loading":_vm.lawyer_loading,"dense":""},model:{value:(_vm.item.lawyer),callback:function ($$v) {_vm.$set(_vm.item, "lawyer", $$v)},expression:"item.lawyer"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"eager","vid":"lawyer_appointing_date","name":"Lawyer Appointing Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of appointing lawyer","hint":"Date of appointing lawyer","prepend-icon":"mdi-calendar","readonly":"","required":"","dense":""},model:{value:(_vm.item.lawyer_engage_date),callback:function ($$v) {_vm.$set(_vm.item, "lawyer_engage_date", $$v)},expression:"item.lawyer_engage_date"}},'v-text-field',attrs,false),on)),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})]}}],null,true),model:{value:(_vm.lawyer_appointing_date_menu),callback:function ($$v) {_vm.lawyer_appointing_date_menu=$$v},expression:"lawyer_appointing_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.lawyer_appointing_date_menu=false}},model:{value:(_vm.item.lawyer_engage_date),callback:function ($$v) {_vm.$set(_vm.item, "lawyer_engage_date", $$v)},expression:"item.lawyer_engage_date"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"district","name":"District","rules":"objectNotEmpty:district|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"District*","hint":"District","items":_vm.districts,"item-text":function (item){ return item.name; },"item-value":"id","requiredkl":"","autocomplete":"nope","loading":_vm.district_loading,"dense":""},model:{value:(_vm.item.district),callback:function ($$v) {_vm.$set(_vm.item, "district", $$v)},expression:"item.district"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"court_type","name":"Court Type","rules":"objectNotEmpty:court_type|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Court Type*","hint":"Search by court type name","items":_vm.court_types,"item-text":function (item){ return item.name; },"item-value":"id","required":"","autocomplete":"nope","loading":_vm.court_types_loading,"dense":""},model:{value:(_vm.court_type),callback:function ($$v) {_vm.court_type=$$v},expression:"court_type"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"court","name":"Court","rules":"objectNotEmpty:court|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Court*","hint":"Search by court name","items":_vm.courts,"item-text":function (item){ return item.name; },"item-value":"id","required":"","autocomplete":"nope","loading":_vm.courts_loading,"dense":""},model:{value:(_vm.item.court),callback:function ($$v) {_vm.$set(_vm.item, "court", $$v)},expression:"item.court"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"court_no","name":"Case No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Court No*","hint":"Court No","required":"","autocomplete":"nope","maxlength":"256","counter":"","dense":""},model:{value:(_vm.item.court_no),callback:function ($$v) {_vm.$set(_vm.item, "court_no", $$v)},expression:"item.court_no"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"eager","vid":"next_date","name":"Next Date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Next Date","hint":"Next court Date","prepend-icon":"mdi-calendar","readonly":"","dense":"","clearable":""},model:{value:(_vm.item.next_date),callback:function ($$v) {_vm.$set(_vm.item, "next_date", $$v)},expression:"item.next_date"}},'v-text-field',attrs,false),on)),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})]}}],null,true),model:{value:(_vm.next_date_menu),callback:function ($$v) {_vm.next_date_menu=$$v},expression:"next_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.next_date_menu=false}},model:{value:(_vm.item.next_date),callback:function ($$v) {_vm.$set(_vm.item, "next_date", $$v)},expression:"item.next_date"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"purpose","name":"Purpose","rules":"objectNotEmpty:purpose|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Purpose*","hint":"Purpose","items":_vm.purposes,"item-text":function (item){ return item.name; },"item-value":"id","required":"","autocomplete":"nope","loading":_vm.purposes_loading,"dense":""},model:{value:(_vm.item.purpose),callback:function ($$v) {_vm.$set(_vm.item, "purpose", $$v)},expression:"item.purpose"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"purpose_remarks","name":"Purpose Remarks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Purpose Remarks*","hint":"Purpose Remarks","dense":"","clearable":"","auto-grow":"","rows":"1","counter":"1024"},model:{value:(_vm.item.purpose_remarks),callback:function ($$v) {_vm.$set(_vm.item, "purpose_remarks", $$v)},expression:"item.purpose_remarks"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }