<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="6"
              >
                <v-menu
                    v-model="date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="date" name="Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.date"
                          label="Transaction Date*"
                          hint="Transaction Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                          clearable
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.date"
                      @input="date_menu=false"
                      :max="max_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="6"
              >
                <ValidationProvider mode="passive" vid="amount" name="Amount"
                                    rules="required|min_value:1"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Transaction Amount*"
                      hint="Transaction Amount"
                      required
                      autocomplete="nope"
                      v-model="item.amount"
                      maxlength="32"
                      counter
                      clearable
                      dense
                      type="number"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="6"
              >
                <ValidationProvider mode="passive" vid="mode_of_recovery" name="Mode of Recovery"
                                    rules="objectNotEmpty:mode_of_recovery|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Mode of Recovery*"
                      hint="Mode of recovery"
                      :items="mode_of_recovery"
                      :item-text="(item)=>item.name"
                      item-value="id"
                      v-model="item.mode_of_recovery"
                      required
                      autocomplete="nope"
                      :loading="mode_of_recovery_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="6"
              >
                <ValidationProvider mode="passive" vid="remarks" name="Remarks"
                                    v-slot="{ errors }">
                  <v-textarea
                      v-model="item.remarks"
                      label="Remarks"
                      hint="Remarks or Transaction details"
                      dense
                      clearable
                      clear-icon="mdi-close-circle"
                      rows="1"
                      auto-grow
                  ></v-textarea>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import parsing_functions from "@/constants/util_functions/parsing_functions";
import app_setting_api from "@/constants/app_setting/app_setting_api";
import {user_urls} from "@/constants/user_urls";

export default {
  name: "AddRecoveryForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text',
    'tooltip_text', 'loading', 'color', 'small', 'file', 'file_conn', 'passed_item', 'mamla_conn', 'mamla'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    max_date: function () {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      return String(yyyy + '-' + mm + '-' + dd)
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    tr_date_menu: false,
    mode_of_recovery: [],
    mode_of_recovery_loading: false,
    date_menu: null,
    item: {
      remarks: null,
    },
    non_field_errors: [],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        file: this.file,
        mamla: this.mamla,
        ...this.item
      }
      console.log(this.mamla)
      await axios.post(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.case_no),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                case_number: error.response.data.errors.case_number,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      }
    },
    get_recovery() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.mode_of_recovery = []
      this.mode_of_recovery_loading = true
      axios.get(app_setting_api.mode_of_recovery.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.mode_of_recovery = resp.data.items
        this.mode_of_recovery_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get mode of recovery data", status: true})
        this.$refs.observer.setErrors({
          court: ["Unable to get mode of recovery data, contact admin"],
        });
      })
    },
    load_data() {
      this.get_recovery()
    }
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.item = JSON.parse(JSON.stringify(newValue))
          this.item.date = parsing_functions.parse_date_to_standard(this.item.date)
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>