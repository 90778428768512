<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9">
          <span class="font-weight-bold">
            Second Step
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
        <v-card class="my-3 elevation-4 ma-0 pa-0">
          <v-tabs
              v-model="tab"
              background-color="orange"
              color="light-green accent-4"
              centered
              icons-and-text
              dense
              show-arrows
              class="ma-0 pa-0"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="flow1">
              <span class="black--text">First Appeal (HC)</span>
              <v-icon class="black--text">mdi-file-tree</v-icon>
            </v-tab>
            <!--            <v-tab v-if="flow2">-->
            <!--              <span class="black&#45;&#45;text">Writ</span>-->
            <!--              <v-icon class="black&#45;&#45;text">mdi-file-tree</v-icon>-->
            <!--            </v-tab>-->
          </v-tabs>
          <v-tabs-items v-model="tab" class="amber lighten-4 ma-0 pa-0">
            <v-tab-item v-if="flow1">
              <Flow1 :parent="parent" :seq="seq" flow="1"/>
            </v-tab-item>
            <!--            <v-tab-item v-if="flow2">-->
            <!--              <Flow2 :parent="parent" :seq="seq" flow="2"/>-->
            <!--            </v-tab-item>-->
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Flow1 from "@/components/mamla/BankruptcyFlows/Flow1";
// import Flow2 from "@/components/mamla/BankruptcyFlows/Flow2";

import first_apeal_hc_mamla_perms from "@/constants/permissions/first_apeal_hc_perms";
import writ_mamla_perms from "@/constants/permissions/writ_perms";

export default {
  name: "SecStep",
  props: ['parent', 'seq'],
  components: {
    Flow1,
    // Flow2
  },
  computed: {
    flow1() {
      return this.$store.getters.permissionCheckers(first_apeal_hc_mamla_perms.view)
    },
    flow2() {
      return this.$store.getters.permissionCheckers(writ_mamla_perms.view)
    },
  },
  data: () => ({
    tab: null,
  })
}
</script>

<style scoped>

</style>