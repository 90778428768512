var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},on:{"input":_vm.load_data},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"color":_vm.color,"small":_vm.small}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}],null,true)},[(!_vm.big_btn)?_c('span',[_vm._v(_vm._s(_vm.tooltip_text))]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"non_field","name":"None Field","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((errors),function(err){return _c('ul',{key:err},[_c('li',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])])})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"eager","vid":"date_of_declaring_bankruptcy","name":"Date of declaring bankrupt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of declaring bankrupt*","hint":"Date of declaring bankrupt","prepend-icon":"mdi-calendar","readonly":"","required":"","dense":""},model:{value:(_vm.item.date_of_declaring_bankruptcy),callback:function ($$v) {_vm.$set(_vm.item, "date_of_declaring_bankruptcy", $$v)},expression:"item.date_of_declaring_bankruptcy"}},'v-text-field',attrs,false),on)),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})]}}],null,true),model:{value:(_vm.date_of_declaring_bankruptcy_menu),callback:function ($$v) {_vm.date_of_declaring_bankruptcy_menu=$$v},expression:"date_of_declaring_bankruptcy_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_of_declaring_bankruptcy_menu=false}},model:{value:(_vm.item.date_of_declaring_bankruptcy),callback:function ($$v) {_vm.$set(_vm.item, "date_of_declaring_bankruptcy", $$v)},expression:"item.date_of_declaring_bankruptcy"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"nudge-right":40,"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"mode":"eager","vid":"date_of_appointing_receiver","name":"Date of appointing receiver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of appointing receiver*","hint":"Date of appointing receiver","prepend-icon":"mdi-calendar","readonly":"","required":"","dense":""},model:{value:(_vm.item.date_of_appointing_receiver),callback:function ($$v) {_vm.$set(_vm.item, "date_of_appointing_receiver", $$v)},expression:"item.date_of_appointing_receiver"}},'v-text-field',attrs,false),on)),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})]}}],null,true),model:{value:(_vm.date_of_appointing_receiver_menu),callback:function ($$v) {_vm.date_of_appointing_receiver_menu=$$v},expression:"date_of_appointing_receiver_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_of_appointing_receiver_menu=false}},model:{value:(_vm.item.date_of_appointing_receiver),callback:function ($$v) {_vm.$set(_vm.item, "date_of_appointing_receiver", $$v)},expression:"item.date_of_appointing_receiver"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"bankruptcy_receiver","name":"Name of Receiver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name of  Receiver*","hint":"Bankruptcy Receiver Name","required":"","autocomplete":"nope","maxlength":"256","counter":"","dense":""},model:{value:(_vm.item.bankruptcy_receiver),callback:function ($$v) {_vm.$set(_vm.item, "bankruptcy_receiver", $$v)},expression:"item.bankruptcy_receiver"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"bankruptcy_receiver_address","name":"bankruptcy_receiver_address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"bankruptcy_receiver_address*","hint":"bankruptcy_receiver_address","dense":"","clearable":"","auto-grow":""},model:{value:(_vm.item.bankruptcy_receiver_address),callback:function ($$v) {_vm.$set(_vm.item, "bankruptcy_receiver_address", $$v)},expression:"item.bankruptcy_receiver_address"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"bankruptcy_receiver_contact_no","name":"Receiver Contact No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Receiver Contact No*","hint":"Receiver Contact No","required":"","autocomplete":"nope","maxlength":"14","counter":"","dense":"","type":"number"},model:{value:(_vm.item.bankruptcy_receiver_contact_no),callback:function ($$v) {_vm.$set(_vm.item, "bankruptcy_receiver_contact_no", $$v)},expression:"item.bankruptcy_receiver_contact_no"}}),_c('ul',_vm._l((errors),function(err){return _c('li',{key:err},[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])}),0)]}}],null,true)})],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }