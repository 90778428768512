<template>
  <v-container class="ma-0 pa-0">
    <v-skeleton-loader
        type="table-heading, list-item-two-line,table-tfoot"
        class="mx-auto"
        v-bind="attrs"
        v-if="loader"
    >
    </v-skeleton-loader>
    <v-container v-if="!loader" class="pa-0 ma-0">
      <v-row align="center" justify="center" class="my-3" v-if="view_buttons">
        <v-col cols="6" align="center" justify="center">
          <AddBankruptcyCaseForm v-if="add_button" activator_name="Add New Mamla"
                                 form_title="Add New"
                                 :url="urls.add_url" :file="this.$route.params.fileid"
                                 :seq=seq
                                 activator_icon="mdi-plus" action="add"
                                 tooltip_text="Add New"
                                 big_btn="true" color="orange" @done_event="mamla_updated"
                                 snackbar_text="New Bankruptcy Mamla is added successfully"/>
        </v-col>
        <v-col cols="6" align="center" justify="center">
          <AddBlankDCCaseForm v-if="add_blank_button" @done_event="mamla_updated"
                              :file="this.$route.params.fileid"
                              dialogue_text="Are you sure you, you want to create a blank entry?"
                              :url="urls.blank_url"
                              :seq=seq
                              activator_name="Create a blank Entry"
                              activator_icon="mdi-file-hidden"
                              tooltip_text="Add Blank Entry"
                              snackbar_text="Blank Bankruptcy Mamla has been created"
                              action="blank"
                              big_btn="true" color="orange" :small=false>
          </AddBlankDCCaseForm>
        </v-col>
      </v-row>
      <v-container v-if="!view_buttons" class="pa-0 ma-0">
        <MamlaDetails :info="info" banner_text="Bankruptcy Mamla"/>
        <Actions :info="info" @update_event="update_event_handler" @delete_event="delete_event_hanlder"/>
        <HistoryViews :info="info"/>
        <slot name="cr_session_slot" :parent="info" :seq="parseInt(seq)+1">
          <SecStep :parent="info" :seq="parseInt(seq)+1" class="my-1"/>
        </slot>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import AddBlankDCCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/artho_apeal_dc/sub_components/AddBlankDCCaseForm";
import AddBankruptcyCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/bankruptcy/sub_components/AddBankruptcyCaseForm";
import Actions from "@/components/mamla/loan_mamla/loan_mamla_sub_components/bankruptcy/sub_components/Actions";
import HistoryViews from "@/components/mamla/loan_mamla/common_components/HistoryViews";
import MamlaDetails
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/bankruptcy/sub_components/MamlaDetails";
import bankruptcy_perms from "@/constants/permissions/bankruptcy_perms";
import bankruptcy_urls from "@/constants/mamla/loan_mamla/bankruptcy_urls";
import SecStep from "@/components/mamla/loan_mamla/loan_mamla_sub_components/bankruptcy/sub_components/SecStep";

export default {
  name: "Bankruptcy",
  props: ['parent', 'seq', 'clicked'],
  components: {
    SecStep,
    MamlaDetails,
    AddBlankDCCaseForm, Actions, HistoryViews, AddBankruptcyCaseForm
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    view_buttons() {
      return !this.loader && !this.info
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers(bankruptcy_perms.add) && !this.loader && !this.info
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers(bankruptcy_perms.blank) && !this.loader && !this.info
    },
    view_details() {
      return !this.loader && this.info
    }
  },
  data: () => ({
    urls: {
      get_url: bankruptcy_urls.get_by_file,
      blank_url: bankruptcy_urls.blank,
      add_url: bankruptcy_urls.add,
    },
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    loader: true,
    info: {},
  }),
  methods: {
    get_info() {
      this.loader = true
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      axios.get(this.urls.get_url.replace('{fileid}', this.$route.params.fileid).replace('{seq}', this.seq), config).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.items[0]
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    mamla_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_event_handler(value) {
      this.info = value
    }
    ,
    delete_event_hanlder() {
      this.get_info()
    }
  },
  mounted() {
    // this.get_info()
  },
  watch: {
    clicked: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.get_info()
        }
      }
    }
  },

}
</script>

<style scoped>

</style>