<script>
import WritContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ_as_different_flows/WritContainer.vue";
import commonInfo from "@/components/nl_file/sub_components/commonInfo.vue";
import bankruptcyContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/bankruptcy/BankruptcyContainer.vue";
import companyMatterContainer from "@/components/mamla/companyMatter/companyMatterContainer.vue";

export default {
  name: "nlFileDetails",
  components: {commonInfo, WritContainer, bankruptcyContainer, companyMatterContainer},
  provide: {
    isNlFile: true
  }
}
</script>

<template>
  <v-container class="px-0 ma-0" style="font-max-size: small">
    <commonInfo/>
    <WritContainer class="my-2"></WritContainer>
    <bankruptcy-container class="my-2"/>
    <companyMatterContainer class="my-2"/>
  </v-container>
</template>

<style scoped>

</style>