<template>
  <v-container v-if="has_view_permission" class="pa-0 ma-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="subjects_table"
        :search="search"
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          <AddRecoveryForm
              v-if="add_button" activator_name="Add Recovery transaction"
              form_title="Add Recovery Transaction"
              :url="add_url.replace('{mamlaid}',mamla)" :file="file" :mamla="mamla"
              activator_icon="mdi-plus" action="add"
              tooltip_text="Add Recovery"
              big_btn="true" color="white" @done_event="get_lists"
              snackbar_text="Recovery Transaction Added"
              :small=false
          />
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
          <span class="mx-2">Total Recovery: {{ total }}</span>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
          ></v-text-field>
        </v-card-title>
      </template>
      <template v-slot:item.actions="{item}">
        <delete_dialog v-if="delete_button" @form_status="get_lists" :passed_item="item"
                       :dialogue_text="`Are you sure you want to Delete recovery transaction?`"
                       :url="`${delete_url.replace('{trid}', item.id)}`"
                       activator_name="Delete" activator_icon="mdi-delete"
                       tooltip_text="Delete"
                       snackbar_text="Artho Rin Recovery Transaction has been deleted"
                       action="delete"
                       big_btn="true" color="orange" :small=true
        ></delete_dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import axios from "axios";
import common_info_urls from "@/constants/mamla/common_info";

import AddRecoveryForm from "@/components/mamla/loan_mamla/common_components/sub_components/recovery/AddRecoveryForm";
import delete_dialog from "@/components/mamla/loan_mamla/common_components/sub_components/recovery/delete_dialog";

export default {
  name: "RecoveryTransactions",
  props: ['mamla_obj', 'mamla', 'file',],
  components: {delete_dialog, AddRecoveryForm},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('mamla_common_info.view_recoverytransaction')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('mamla_common_info.add_recoverytransaction') && !this.mamla_obj.dispose_status
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('mamla_common_info.delete_recoverytransaction') && !this.mamla_obj.dispose_status
    },
  },
  data: () => ({
    table_items: [],
    clicked: false,
    show_snackbar: false,
    snackbar_text: '',
    list_url: common_info_urls.recovery.list,
    add_url: common_info_urls.recovery.add,
    delete_url: common_info_urls.recovery.single,
    table_loading: false,
    reload_btn: false,
    search: '',
    expanded: false,
    total: 0,
    column_headers: [
      {
        text: 'Transaction Date',
        align: 'center',
        filterable: true,
        justify: 'center',
        value: 'date',
      },
      {
        text: 'Amount',
        align: 'center',
        filterable: true,
        value: 'amount',
      },
      {
        text: 'Mode of Recovery',
        align: 'center',
        filterable: true,
        justify: 'center',
        value: 'mode_of_recovery.name',
      },
      {
        text: 'Remarks',
        align: 'center',
        filterable: true,
        value: 'remarks',
      },
      {
        text: 'User',
        align: 'center',
        filterable: true,
        value: 'user.username',
      },
      {
        text: 'Timestamp',
        align: 'center',
        filterable: true,
        value: 'timestamp',
      },
      {
        text: 'Period',
        align: 'center',
        filterable: true,
        value: 'period.name',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: true,
        value: 'actions',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(this.list_url.replace('{mamlaid}', this.mamla), config).then((resp) => {
        this.table_items = resp.data.items
        this.total = resp.data.total ? resp.data.total : 0
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
    expand() {
      this.expanded = !this.expanded
      if (this.expanded) {
        this.get_lists()
      }
    },
  },
  mounted() {
    this.get_lists()
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler() {
        if (this.expanded) {
          this.get_lists()
        }
      },
    },
  }
}
</script>

<style>
div[name="subjects_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>