<template>
  <v-container class="ma-0 pa-0">
    <v-skeleton-loader
        type="table-heading, list-item-two-line,table-tfoot"
        class="mx-auto"
        v-bind="attrs"
        v-if="loader"
    >
    </v-skeleton-loader>
    <v-container v-if="!loader" class="pa-0 ma-0">
      <v-container v-if="!view_buttons" class="pa-0 ma-0">
        <MamlaDetails :info="info" :banner_text="title"/>
        <Actions :info="info" @update_event="update_event_handler" @delete_event="delete_event_hanlder"/>
        <HistoryViews :info="info"/>
        <slot name="writ_child" :parent="info" :seq="parseInt(seq)+1" :flow="flow">
          <firstAppealHcContainer :parent="info" :seq="parseInt(seq)+1" :flow="flow"
                                  class="my-1"/>
        </slot>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import Actions from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/Actions";
import HistoryViews from "@/components/mamla/loan_mamla/common_components/HistoryViews";
import companyMatterUrls from "@/constants/urls/companyMatterUrls";
import MamlaDetails from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/MamlaDetails";
import firstAppealHcContainer
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/first_appeal_hc/FirstAppealHcContainer.vue";
import createAxiosClient from "@/helpers/axios_helper";


export default {
  name: "childCase",
  props: ['item', 'seq', 'flow', 'child_writ_clicked', 'title', 'permArray'],
  components: {
    MamlaDetails,
    Actions, HistoryViews, firstAppealHcContainer
  },
  computed: {
    view_buttons() {
      return !this.loader && !this.info
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers(this.permArray.add) && !this.loader && !this.info
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers(this.permArray.add_blank) && !this.loader && !this.info
    },
    view_details() {
      return !this.loader && this.info
    },
  },
  data: () => ({
    urls: {
      get_url: companyMatterUrls.single,
    },
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    loader: true,
    info: {},
  }),
  methods: {
    async get_info() {
      this.loader = true
      let client = createAxiosClient()
      console.log("item id: ", this.item.id)
      client.get(this.urls.get_url + this.item.id).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.data[0]
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    mamla_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_event_handler(value) {
      this.info = value
      this.$emit('update_signal', true)
    }
    ,
    delete_event_hanlder() {
      this.$emit('delete_signal', true)
    }
  },
  mounted() {
    this.get_info()
  },
  watch: {
    child_writ_clicked: async function (val, oldVal) {
      oldVal
      if (val) {
        await this.get_info()
      }
    }
  }

}
</script>

<style scoped>

</style>