<template>
  <v-data-table
      :headers="column_headers"
      :items="table_items"
      class="elevation-5 yellow lighten-2"
      :loading="table_loading"
      loading-text="Loading... Please wait"
      item-key="id"
      name="subjects_table"
      :search="search"
  >
    <template v-slot:top>
      <v-card-title
          flat
      >
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mx-3"
        ></v-text-field>
      </v-card-title>
    </template>
  </v-data-table>
</template>

<script>


import axios from "axios";
import loan_mamla_urls from "@/constants/mamla/loan_mamla/loan_mamla";

export default {
  name: "NextDayPurposeHistory",
  props: ['passed_item'],
  components: {},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('mamla_container.view_lawyerhistory')
    },
  },
  data: () => ({
    table_items: [],
    clicked: false,
    show_snackbar: false,
    snackbar_text: '',
    list_url: loan_mamla_urls.history.next_date.list,
    table_loading: false,
    reload_btn: false,
    search: '',
    expanded: false,
    column_headers: [
      {
        text: 'Date',
        align: 'center',
        filterable: true,
        value: 'next_date',
      },
      {
        text: 'Purpose',
        align: 'center',
        filterable: true,
        value: 'purpose.name',
      },
      {
        text: 'Input Timestamp',
        align: 'center',
        filterable: true,
        value: 'timestamp',
      },
      {
        text: 'User',
        align: 'center',
        filterable: true,
        value: 'user.username',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(this.list_url.replace('{mamla_id}', this.passed_item.id), config).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
    expand() {
      this.expanded = !this.expanded
      if (this.expanded) {
        this.get_lists()
      }
    },
  },
  mounted() {
    this.get_lists()
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler() {
        {
          this.get_lists()
        }
      },
    },
  }
}
</script>

<style>
div[name="subjects_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>