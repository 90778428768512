<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="6"
              >
                <ValidationProvider mode="eager" vid="lawyer" name="Lawyer"
                                    rules="objectNotEmpty:lawyer|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Lawyer*"
                      hint="Search by lawyer name and mobile no"
                      :items="lawyer"
                      :item-text="(item)=>item.name + ' | ' + item.mobile_no"
                      item-value="id"
                      v-model="item.lawyer"
                      requiredkl
                      autocomplete="nope"
                      :loading="lawyer_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="6"
              >

                <v-menu
                    v-model="lawyer_appointing_date_menu"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider mode="eager" vid="lawyer_appointing_date" name="Lawyer Appointing Date"
                                        rules="required"
                                        v-slot="{ errors }">
                      <v-text-field
                          v-model="item.lawyer_engage_date"
                          label="Date of appointing lawyer"
                          hint="Date of appointing lawyer"
                          prepend-icon="mdi-calendar"
                          readonly
                          required
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                      <ul>
                        <li v-for="err in errors" :key="err">
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                      v-model="item.lawyer_engage_date"
                      @input="lawyer_appointing_date_menu=false"
                      :max="max_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import personnel_urls from "@/constants/personnel_urls";
// import app_setting_api from "@/constants/app_setting/app_setting_api";
import parsing_functions from "@/constants/util_functions/parsing_functions";

export default {
  name: "ChangeArthoRinLawyerForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading', 'color', 'small', 'file', 'file_conn', 'passed_item'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    max_date: function () {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      return String(yyyy + '-' + mm + '-' + dd)
    }
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    lawyer_appointing_date_menu: false,
    lawyer_loading: false,
    item: {
      lawyer: null,
      lawyer_engage_date: "",
    },
    lawyer: [],
    non_field_errors: [],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      
      let data = {
        id: this.passed_item.id,
        ...this.item
      }
      await axios.patch(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            // this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.case_no),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                case_number: error.response.data.errors.case_number,
                filing_date: error.response.data.errors.filing_date,
                claimed_amnt: error.response.data.errors.claimed_amnt,
                lawyer: error.response.data.errors.lawyer,
                lawyer_engage_date: error.response.data.errors.lawyer_engage_date,
                next_date: error.response.data.errors.next_date,
                purpose: error.response.data.errors.purpose,
                purpose_remarks: error.response.data.errors.purpose_remarks,
                district: error.response.data.errors.district,
                court: error.response.data.errors.court,
                court_no: error.response.data.errors.court_no,
                non_field: error.response.data.errors.non_field,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      this.add_new_item()
    },
    get_lawyer() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.lawyer_loading = true
      axios.get(personnel_urls.lawyer.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.lawyer = resp.data.items
        this.lawyer_loading = false
      }).catch((err) => {
        if (err.response) {
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Unable to get lawyer data", status: true})
        this.$refs.observer.setErrors({
          lawyer: ["Unable to get lawyer data"],
        });
      })
    },
    load_data() {
      this.get_lawyer()
    }
  },
  watch: {
    passed_item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        let passed = JSON.parse(JSON.stringify(newValue))
        this.item.lawyer = passed.lawyer ? passed.lawyer.id : null
        this.item.lawyer_engage_date = passed.lawyer_engage_date ? parsing_functions.parse_date_to_standard(passed.lawyer_engage_date) : null
        // console.log(this.item)
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>