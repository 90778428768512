<template>
  <v-dialog v-model="dialog" max-width="50%">
    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>
    <v-card class="warning justify-center">
      <v-card-title class="text-h5 justify-center">
        <v-row>
          <span v-if="dialog_error" class="text-center red--text font-weight-bold">{{ dialog_error }}</span>
        </v-row>
        <v-row>
          <span class="text-center">{{ dialogue_text }}</span>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog=false">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="submit_loading" text @click="handle_submit">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "delete_dialog",
  props: ['passed_item', 'dialogue_text', 'activator_icon', 'tooltip_text', 'url', 'action', 'activator_name', 'big_btn', 'snackbar_text', 'color', 'small'],
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    dialog_error: null,
    loading: false
  }),
  methods: {
    async handle_submit() {
      this.axios_conf
      this.submit_loading = true
      if (this.action === 'delete') {
        await axios.delete(this.url)
            .then((resp) => {
              this.$store.commit('setJWT', resp.data.key)
              this.dialog = false
              this.$emit('form_status', true)
              this.$store.commit("update_snackbar", {
                text: this.snackbar_text,
                status: true
              })
            })
        // .catch((error) => {
        //   // console.log(error)
        // })
      }
      // else if (this.action === 'authorize') {
      //   let data = JSON.parse(JSON.stringify(this.passed_item))
      //   let self = this
      //   await axios.post(this.url, data)
      //       .then((resp) => {
      //         this.$store.commit('setJWT', resp.data.key)
      //         this.dialog = false
      //         this.$emit('form_status', true)
      //         this.$store.commit("update_snackbar", {
      //           text: this.snackbar_text.replace('{value}', self.passed_item.name),
      //           status: true
      //         })
      //       }).catch((error) => {
      //         if (error.response.data) {
      //           this.$store.commit('setJWT', error.response.data.key)
      //           this.dialog_error = error.response.data.errors
      //         }
      //       })
      // } else if (this.action === 'close') {
      //   let self = this
      //   await axios.patch(this.url)
      //       .then((resp) => {
      //         this.$store.commit('setJWT', resp.data.key)
      //         this.dialog = false
      //         this.$emit('form_status', true)
      //         this.$store.commit("update_snackbar", {
      //           text: this.snackbar_text.replace('{value}', self.passed_item.name),
      //           status: true
      //         })
      //       }).catch((error) => {
      //         if (error.response.data) {
      //           this.$store.commit('setJWT', error.response.data.key)
      //           this.dialog_error = error.response.data.errors
      //         }
      //       })
      // }
      this.submit_loading = false
    },
  },
  // watch: {
  //   zone(val) {
  //     return this.circle = val
  //   },
  // }
}
</script>

<style scoped>

</style>