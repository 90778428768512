const nls_urls = {
    'subject': {
        list: 'nls/subject/',
        add: 'nls/subject/',
        edit: 'nls/subject/{id}',
        delete: 'nls/subject/{id}',
        single: 'nls/subject/{id}',
    },
    'owner': {
        by_subject: 'nls/owner/subject/{subject}',
        all: 'nls/owner',
        add: 'nls/owner/',
        edit: 'nls/owner/{id}',
        delete: 'nls/owner/{id}',
        single: 'nls/owner/{id}',
    }
};

export default nls_urls;