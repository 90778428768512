<template>
  <v-container v-if="has_view_permission" class="ma-0 pa-0">
    <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
      <v-expansion-panel class="ma-0 pa-0">
        <v-expansion-panel-header
            class="font-weight-bold "
            color="amber accent-1"
            expand-icon="mdi-account-multiple"
            @click="expanded"
        >
          <span class="text-h6">Common Information</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
            color="amber lighten-4"
            class="ma-0 pa-0"
        >
          <v-skeleton-loader
              type="table-heading, list-item-two-line,table-tfoot"
              class="mx-auto"
              v-bind="attrs"
              v-if="loader"
          >
          </v-skeleton-loader>
          <v-container v-if="!loader" class="ma-0 pa-0">
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Tracking Number</span>
              </v-col>
              <v-col cols="3" class="text-center">
                <span class="font-weight-bold">{{ info.tracking_number }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Involved Amount</span>
              </v-col>
              <v-col cols="3" class="text-center">
                <span>{{ formatted_involved_amount }}</span>
                <add_edit_form v-if="edit_involved_amount(info.file_closed)" activator_name="Edit"
                               form_title="Edit Involved Amount" :passed_item="info"
                               :url="change_involved_amount" @involved_amount="involved_amount"
                               activator_icon="mdi-pencil" action="edit"
                               tooltip_text="Edit Involved Amount"
                               snackbar_text="Involved amount has been changed successfully">
                </add_edit_form>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Subject Name</span>
              </v-col>
              <v-col cols="3" class="text-center">
                <span>{{ info.nls_subject.name }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Phone</span>
              </v-col>
              <v-col cols="3" class="text-center">
                <span>{{ info.nls_subject.phone }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-row justify="center">
              <v-col cols="3" class="text-left">
                <span class="font-weight-bold">Address</span>
              </v-col>
              <v-col cols="3" class="text-center">
                <span style="overflow-wrap: break-word;">{{ info.nls_subject.address }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="3" class="text-center">
              </v-col>
              <v-col cols="3" class="text-center">
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center">
                <v-expansion-panels class="elevation-9 rounded-xl">
                  <v-expansion-panel>
                    <v-expansion-panel-header color="orange" expand-icon="mdi-account-tie" class="elevation-9">
                      <span class="font-weight-bold">
                        Owners
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="amber lighten-4 ">
                      <v-container v-for="owner in owner_info" :key="owner.id">
                        <v-row justify="center" align="center">
                          <v-col cols="3" class="text-center">
                            <span class="font-weight-bold">Owner Type</span>
                          </v-col>
                          <v-col cols="3" class="text-center">
                            <span>{{ owner.owner_type.name }}</span>
                          </v-col>
                          <v-col cols="3" class="text-center">
                            <span class="font-weight-bold">Subject Code</span>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="3" class="text-center">
                            <span>{{ owner.subject_code }}</span>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="center">
                          <v-col cols="3" class="text-center">
                            <span class="font-weight-bold">Subject Code</span>
                          </v-col>
                          <v-col cols="3" class="text-center">
                            <span>{{ owner.subject_name }}</span>
                          </v-col>
                          <v-divider vertical></v-divider>
                          <v-col cols="3" class="text-center">
                            <span class="font-weight-bold">NID</span>
                          </v-col>
                          <v-col cols="3" class="text-center">
                            <span>{{ owner.nid }}</span>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <dealing-officer :passed_item="info" @update_dealing_officer="update_dealing_officer"></dealing-officer>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from "axios";
import NlFile from "@/constants/urls/nl_file"
import DealingOfficer from "@/components/mamla/loan_mamla/loan_mamla_sub_components/dealing_officer/DealingOfficer";
import add_edit_form from "@/components/mamla/loan_mamla/loan_mamla_sub_components/involved_amount/add_edit_form";
import nls_urls from "@/constants/urls/nls_urls";

export default {
  name: "CommonInformation",
  components: {DealingOfficer, add_edit_form},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('mamla_container.view_loanmamlafile')
    },
    formatted_involved_amount() {
      return this.info.involved_amount
    },
    change_involved_amount() {
      return NlFile.involved_amount.edit(this.info.id)
    }
  },
  data: () => ({
    // change_involved_amount: NlFile.involved_amount.edit(),
    get_url: NlFile.file.select,
    loader: true,
    clicked: false,
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    owner_info: [],
    info: {
      borrower: {},
      involved_amount: 0,
      branch: {},
      timestamp: {},
      tracking_number: "",
      dealing_officer: {},
      update_by: {},
      update_timestamp: "",
      cib_owner: {}
    },
  }),
  methods: {
    get_info() {
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      axios.get(this.get_url.replace('{fileid}', this.$route.params.fileid), config).then((resp) => {
        // console.log(resp)
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.data
        this.loader = false
        axios.get(nls_urls.owner.by_subject.replace("{subject}", this.info.nls_subject.id)).then((resp) => {
          this.$store.commit('setJWT', resp.data.key)
          this.owner_info = resp.data.data
        })
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
      })
    },
    expanded() {
      this.clicked = !this.clicked
      if (this.clicked) {
        this.get_info()
      }
    },
    involved_amount(value) {
      this.info.involved_amount = Number(value).toFixed(2)
    },
    edit_involved_amount(value) {
      return this.$store.getters.permissionCheckers('mamla_container.edit_involved_amount') && !value
    },
    update_dealing_officer(value) {
      this.info.dealing_officer = value
    }
  },
  mounted() {
  },
  watch: {}
}
</script>

<style scoped>

</style>