const first_appeal_hc_mamla_urls = {
    get_by_file: "mamla/loanmamla/first_appeal_hc_by_file/{fileid}/{seq}/",
    get_by_parent: "mamla/loanmamla/first_appeal_hc_by_parent/{parent}/{seq}/",
    list: "mamla/loanmamla/first_appeal_hc/",
    blank: "mamla/loanmamla/first_appeal_hc_blank/",
    disposal: "mamla/loanmamla/first_appeal_hc_disposal/",
    single: "mamla/loanmamla/first_appeal_hc/{id}/",
    add: "mamla/loanmamla/first_appeal_hc/"
}

export default first_appeal_hc_mamla_urls;