const common_info_urls = {
    recovery: {
        list: "common_info/arthorin_recovery_tr/all/{mamlaid}/",
        single: "common_info/arthorin_recovery_tr/{trid}/",
        add: "common_info/arthorin_recovery_tr/all/{mamlaid}/",
    },
    expense: {
        list: "common_info/arthorin_expense_tr/all/{mamlaid}/",
        single: "common_info/arthorin_expense_tr/{trid}/",
        add: "common_info/arthorin_expense_tr/all/{mamlaid}/",
    },
    lawyer: {
        chnage: 'common_info/change_lawyer/{case_type}/'
    },
    next_date: {
        chnage: 'common_info/next_date/{case_type}/'
    },
    case_info: {
        chnage: 'common_info/case_info/{case_type}/',
        change_hc: 'common_info/hc_case_info/{case_type}/',
        cr_session: 'common_info/cr_case_info/{case_type}/',
    },
    court_info: {
        chnage: 'common_info/court_info/{case_type}/'
    },
    rule_issue: {
        sc: 'common_info/rule_issue/'
    },
    affidavit: {
        sc: 'common_info/affidavit/'
    },
    stay_order: {
        sc: 'common_info/stay_order/'
    },
    judgement_cert: {
        sc: 'common_info/judgement_cert/'
    },
    change_cheque_info: {
        cr: 'common_info/change_cheque_info/'
    },
    change_session_info: {
        cr: 'common_info/change_cr_session_info/'
    },
    change_bankruptcy_info: {
        bankruptcy: 'common_info/change_bankruptcy_info/'
    }
};

export default common_info_urls;
