<template>
  <v-row justify="center" class="my-3">
    <v-col cols="auto" color="orange" align-self="center" class="mx-1">
      <delete_dialog v-if="delete_button" :passed_item="info"
                     :dialogue_text="`Are you sure you want to Delete this Case?`"
                     :url="`${urls.delete_url.replace('{id}', info.id)}`"
                     activator_name="Delete Case" activator_icon="mdi-delete"
                     tooltip_text="Delete"
                     snackbar_text="Case has been deleted"
                     action="delete"
                     big_btn="true" color="orange" :small=true
                     @done_event="delete_data"
      ></delete_dialog>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionLawyerForm v-if="change_lawyer_button" activator_name="Change Lawyer Info"
                                 form_title="Change Lawyer Information"
                                 :url="urls.change_lawyer.replace('{case_type}', info.case_type.code)"
                                 :passed_item="info"
                                 activator_icon="mdi-account-switch" action="add"
                                 tooltip_text="Change Lawyer"
                                 big_btn="true" color="orange" @done_event="update_data"
                                 snackbar_text="Lawyer Information Updated Successfully"
                                 :small=true>
      </ChangeExecutionLawyerForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeSCCaseForm v-if="change_case_info_button" activator_name="Change Case Info"
                        form_title="Change Case Information"
                        :url="urls.case_info.replace('{case_type}', info.case_type.code)"
                        :passed_item="info"
                        activator_icon="mdi-file-key" action="add"
                        tooltip_text="Change Case Info"
                        big_btn="true" color="orange" @done_event="update_data"
                        snackbar_text="Case Information Updated Successfully"
                        :small=true>
      </ChangeSCCaseForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionCourtForm v-if="change_court_info_button" activator_name="Change Court Info"
                                form_title="Change Court Information"
                                :url="urls.court_info.replace('{case_type}', info.case_type.code)"
                                :passed_item="info"
                                activator_icon="mdi-office-building-marker-outline" action="add"
                                tooltip_text="Change Court Info"
                                big_btn="true" color="orange" @done_event="update_data"
                                snackbar_text="Case Court Information Updated Successfully"
                                :small=true>
      </ChangeExecutionCourtForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeExecutionNextDateForm v-if="change_next_date_button" activator_name="Change Next Date"
                                   form_title="Change Next Date"
                                   :url="urls.next_date.replace('{case_type}', info.case_type.code)"
                                   :passed_item="info"
                                   activator_icon="mdi-calendar-edit" action="add"
                                   tooltip_text="Change Next Date"
                                   big_btn="true" color="orange" @done_event="update_data"
                                   snackbar_text="Case Next Date Updated Successfully"
                                   :small=true>
      </ChangeExecutionNextDateForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeRuleIssueForm v-if="change_rule_issue_button" activator_name="Change Rule Issue Date"
                           form_title="Change Rule Issue Date"
                           :url="urls.rule_issue"
                           :passed_item="info"
                           activator_icon="mdi-calendar-edit" action="add"
                           tooltip_text="Change Next Date"
                           big_btn="true" color="orange" @done_event="update_data"
                           snackbar_text="Rule issue data Updated Successfully"
                           :small=true>
      </ChangeRuleIssueForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeAffidavitDateForm v-if="change_affidavit_date_button" activator_name="Change Affidavit Date"
                               form_title="Change Affidavit Date"
                               :url="urls.affidavit_date"
                               :passed_item="info"
                               activator_icon="mdi-calendar-edit" action="add"
                               tooltip_text="Change Affidavit Date"
                               big_btn="true" color="orange" @done_event="update_data"
                               snackbar_text="Affidavit Date Updated Successfully"
                               :small=true>
      </ChangeAffidavitDateForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeStayOrderForm v-if="change_stay_order_button" activator_name="Change Stay Order"
                           form_title="Change Stay Order"
                           :url="urls.stay_order"
                           :passed_item="info"
                           activator_icon="mdi-calendar-edit" action="add"
                           tooltip_text="Change Stay Order"
                           big_btn="true" color="orange" @done_event="update_data"
                           snackbar_text="Stay Order Date Updated Successfully"
                           :small=true>
      </ChangeStayOrderForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <DisposeForm v-if="dispose_button" activator_name="Dispose Mamla"
                   form_title="Dispose Execution"
                   :url="urls.dispose" :file="this.$route.params.fileid"
                   :mamla="info.id"
                   :passed_item="info"
                   activator_icon="mdi-close-box" action="add"
                   tooltip_text="Dispose this mamla"
                   big_btn="true" color="orange" @done_event="update_data"
                   snackbar_text="Case Disposed"
                   :small=true>
      </DisposeForm>
    </v-col>
    <v-col cols="auto" align-self="center" class="mx-1">
      <ChangeJudgementCopyForm v-if="change_judgement_cp_date_button"
                               activator_name="Change Judgement Copy Receiving Date"
                               form_title="Change Judgement Copy Receiving Date"
                               :url="urls.judgement_copy" :file="this.$route.params.fileid"
                               :mamla="info.id"
                               :passed_item="info"
                               activator_icon="mdi-calendar-edit" action="add"
                               tooltip_text="Change Judgement Copy Receiving Date"
                               big_btn="true" color="orange" @done_event="update_data"
                               snackbar_text="Judgement Copy Receiving Date updated successfully"
                               :small=true>
      </ChangeJudgementCopyForm>
    </v-col>
  </v-row>
</template>

<script>
import common_info from "@/constants/mamla/common_info";
import delete_dialog
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/delete_dialog";
import ChangeExecutionLawyerForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionLawyerForm";
import ChangeExecutionCourtForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionCourtForm";
import ChangeExecutionNextDateForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/execution/sub_components/ChangeExecutionNextDateForm";
import DisposeForm from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/DisposeForm";
import first_appeal_hc_mamla_urls from "@/constants/mamla/loan_mamla/first_appeal_hc";
import ChangeSCCaseForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/ChangeSCCaseForm";
import ChangeRuleIssueForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/ChangeRuleIssueForm";
import ChangeAffidavitDateForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/ChangeAffidavitDateForm";
import ChangeStayOrderForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/ChangeStayOrderForm";
import ChangeJudgementCopyForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ/sub_components/ChangeJudgementCopyForm";

export default {
  name: "Actions",
  props: ['info'],
  components: {
    delete_dialog,
    ChangeExecutionLawyerForm,
    ChangeSCCaseForm,
    ChangeExecutionCourtForm,
    ChangeExecutionNextDateForm,
    DisposeForm,
    ChangeRuleIssueForm,
    ChangeAffidavitDateForm,
    ChangeStayOrderForm,
    ChangeJudgementCopyForm
  },
  computed: {
    delete_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.delete_execution') && !this.info.dispose_status
    },
    change_lawyer_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_lawyer') && this.info
    },
    change_case_info_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_case_info') && this.info
    },
    change_court_info_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_court') && this.info
    },
    change_next_date_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.change_next_date') && this.info
    },
    dispose_button() {
      return this.$store.getters.permissionCheckers('mamla_container.dispose_first_appeal_hc') && !this.info.dispose_status
    },
    change_rule_issue_button() {
      return this.$store.getters.permissionCheckers('mamla_container.change_rule_issue') && !this.info.dispose_status
    },
    change_stay_order_button() {
      return this.$store.getters.permissionCheckers('mamla_container.change_stay_order') && !this.info.dispose_status
    },
    change_affidavit_date_button() {
      return this.$store.getters.permissionCheckers('mamla_container.change_affidavit') && !this.info.dispose_status
    },
    change_judgement_cp_date_button() {
      return this.$store.getters.permissionCheckers('mamla_container.change_date_of_judgement_copy') && this.info.dispose_status
    },
  },
  data: () => ({
    urls: {
      dispose: first_appeal_hc_mamla_urls.disposal,
      delete_url: first_appeal_hc_mamla_urls.single,
      change_lawyer: common_info.lawyer.chnage,
      court_info: common_info.court_info.chnage,
      case_info: common_info.case_info.change_hc,
      next_date: common_info.next_date.chnage,
      rule_issue: common_info.rule_issue.sc,
      affidavit_date: common_info.affidavit.sc,
      stay_order: common_info.stay_order.sc,
      judgement_copy: common_info.judgement_cert.sc
    }
  }),
  methods: {
    update_data(value) {
      this.$emit('update_event', value)
    },
    delete_data() {
      this.$emit('delete_event', true)
    }
  }
}
</script>

<style scoped>

</style>