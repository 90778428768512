<template>
  <v-container class="ma-0 pa-0">
    <v-skeleton-loader
        type="table-heading, list-item-two-line,table-tfoot"
        class="mx-auto"
        v-bind="attrs"
        v-if="loader"
    >
    </v-skeleton-loader>
    <v-container v-if="!loader" class="pa-0 ma-0">
      <v-row align="center" justify="center" class="my-3">
        <v-col cols="auto" align="center" justify="center">
          <AddWritForm v-if="add_button" activator_name="Add New Writ Mamla"
                       form_title="Add New"
                       :url="urls.add_url" :file="this.$route.params.fileid"
                       :seq=seq
                       :flow="flow"
                       activator_icon="mdi-plus" action="add"
                       tooltip_text="Add New"
                       big_btn="true" color="orange" @done_event="update_event_handler"
                       snackbar_text="New Writ Mamla is added successfully"
          />
        </v-col>
        <v-col cols="auto" align="center" justify="center">
          <AddBlankWritForm v-if="add_blank_button" @done_event="update_event_handler"
                            :file="this.$route.params.fileid"
                            dialogue_text="Are you sure you, you want to create a blank entry?"
                            :url="urls.blank_url"
                            :seq=seq
                            :flow="flow"
                            activator_name="Create a blank Writ Mamla"
                            activator_icon="mdi-file-hidden"
                            tooltip_text="Blank Writ Mamla"
                            snackbar_text="Blank Writ case has been created"
                            action="blank"
                            big_btn="true" color="orange" :small=false
          >
          </AddBlankWritForm>
        </v-col>
      </v-row>
      <v-expansion-panels class="rounded-xl ma-0 pa-0">
        <v-expansion-panel class="my-1 pa-0" v-for="item in info" :key="item.id">
          <v-expansion-panel-header color="orange" expand-icon="mdi-file-tree" class="elevation-9 ma-0"
                                    @click="child_writ_clicked=!child_writ_clicked">
            <span class="font-weight-bold">
                Writ Mamla
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="amber lighten-4 ma-0 pa-0">
            <WritChild :seq="seq" :flow="flow" :item="item"
                       @delete_signal="delete_event_hanlder" @add_signal="update_event_handler"
                       :child_writ_clicked="child_writ_clicked"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import AddWritForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ_as_different_flows/sub_components/AddWritForm";
import writ_mamla_urls from "@/constants/mamla/loan_mamla/writ_mamla";
import WritChild
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ_as_different_flows/sub_components/WritChild";
import AddBlankWritForm
  from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ_as_different_flows/sub_components/AddBlankWritForm";

export default {
  name: "MultiWrit",
  props: ['writ_clicked'],
  components: {
    AddWritForm,
    WritChild,
    AddBlankWritForm
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    add_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.add_writ') && !this.loader
    },
    add_blank_button: function () {
      return this.$store.getters.permissionCheckers('mamla_container.blank_writ') && !this.loader
    },
  },
  data: () => ({
    urls: {
      get_url: writ_mamla_urls.get_by_file,
      blank_url: writ_mamla_urls.blank,
      add_url: writ_mamla_urls.add,
    },
    seq: 1,
    flow: 7,
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
    loader: true,
    info: {},
    child_writ_clicked: false
  }),
  methods: {
    async get_info() {
      this.loader = true
      this.axios_conf
      let config = {
        headers: {
          multi: 'true'
        }
      }
      axios.get(this.urls.get_url.replace('{fileid}', this.$route.params.fileid), config).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.info = resp.data.items
        this.loader = false
      }).catch((error) => {
        this.$store.commit('setJWT', error.response.data.key)
        this.loader = false
        this.info = {}
      })
    },
    mamla_updated(value) {
      this.loader = false
      this.info = value
      this.$emit('update_signal', true)
    },
    update_event_handler() {
      this.get_info()
    }
    ,
    delete_event_hanlder() {
      this.get_info()
    }
  },
  mounted() {
    this.get_info()
  },
  watch: {
    writ_clicked: {
      handler: async function (val, oldVal) {
        oldVal
        if (val) {
          await this.get_info()
        }
      },
    }
  }

}
</script>

<style scoped>

</style>