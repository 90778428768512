<template>
  <v-expansion-panels class="elevation-9 rounded-xl ma-0 pa-0">
    <v-expansion-panel class="ma-0 pa-0">
      <v-expansion-panel-header color="amber accent-1" expand-icon="mdi-cash-multiple"
                                class="elevation-9 ma-0" @click="writ_clicked=!writ_clicked">
          <span class="text-h6">
            Writ Mamla
          </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="ma-0 pa-0" color="amber lighten-4">
        <MultiWrit :writ_clicked="writ_clicked"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import MultiWrit from "@/components/mamla/loan_mamla/loan_mamla_sub_components/writ_as_different_flows/MultiWrit";

export default {
  name: "WritContainer",
  components: {MultiWrit},
  data: () => ({
    writ_clicked: false,
  })
}
</script>

<style scoped>

</style>