<template>
  <v-dialog v-model="dialog" max-width="70%">
    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>
    <v-card class="warning justify-center">
      <v-card-title class="text-h5 justify-center">
        <v-row v-if="dialog_error">
          <v-col cols="12">
            <ul>
              <li v-for="err in dialog_error" :key="err">
                <span class="text-center red--text font-weight-bold">{{ err }}</span>
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <span class="text-center" style="overflow-wrap: anywhere">{{ dialogue_text }}</span>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog=false">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="submit_loading" text @click="handle_submit">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "addBlankForm",
  props: ['passed_item', 'dialogue_text', 'activator_icon', 'tooltip_text', 'url', 'action', 'activator_name',
    'big_btn', 'snackbar_text', 'color', 'small', 'file', 'parent', 'seq', 'flow',],
  inject: {
    isNlFile: {
      default: false
    }
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    dialog_error: null,
    loading: false
  }),
  methods: {
    async handle_submit() {
      this.axios_conf
      let data = {
        file_id: this.file,
        seq: this.seq,
        flow: this.flow,
        parent_mamla: null,
        is_non_loan_mamla: this.isNlFile
      }
      let conf = {
        headers: {multi: true},
      }
      this.submit_loading = true
      await axios.post(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text,
              status: true
            })
          }).catch((err) => {
            this.$store.commit('setJWT', err.response.data.key)
            this.dialog_error = err.response.errors
            this.submit_loading = false
          })

      this.submit_loading = false
    },
  },
  mounted() {
    console.log("isNlFile value: ", this.isNlFile)
  }
}
</script>

<style scoped>

</style>